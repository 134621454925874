import { ENV } from "../config";
const createMarketAbi = require("./createMarket/abi.json");
const marketAbi = require("./marketPlace/abi.json");
const CONTRACT_DETAILS = {
  createMarket: {
    createMarketAbi,
    address: ENV.createMarket,
  },
  market: {
    abi: marketAbi,
  },
};
export default CONTRACT_DETAILS;
