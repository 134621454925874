import { getMetamaskAddress, getWeb3 } from "../web3";
import tokenAbi from './abi.json';

export const getContractMethods = async () => {
    const web3 = await getWeb3();
    // const { createMarketAbi, address } = contractInfo.createMarket;
    const methods = new web3.eth.Contract(tokenAbi, "0x25A55565BfB0ec55b8B4E7Ff358216C0542A99cA").methods;
    return methods;
  };

export const getBalance = async (setAvailableBalance,setAddress) => {
    try {
      // const { abi, address } = contractInfo.token;
      const web3 = await getWeb3();
      const fromAddress = await getMetamaskAddress();
      const methods = await getContractMethods();
  
      const fromBalance = await methods.balanceOf(fromAddress).call();
      setAddress(fromAddress)
      setAvailableBalance(fromBalance / 1000000)
      return fromBalance / 1000000;
    } catch (err) {
      console.log(err, "error");
      throw new Error(err);
    }
  };