import React, { useState, useEffect, useContext } from "react";
import DashboardHeader from "../../components/DashboardHeader";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import UpdateModal from "../../components/Modal/UpdateModal";
import CreateModal from "../../components/Modal/CreateModal";
import { Box, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAxios, postAxios } from "../../utils/api";
import ResolveModal from "../../components/Modal/ResolveModal";

import { resolveMarket } from "../../contracts/marketPlace/index";
import { useSelector, useDispatch } from "react-redux";
import { addTransaction } from "../../redux/actions/transaction";
import SocketContext from "../../context/socket";
import Loader from "../../components/Common/Loader";
import { distributeLiquidity } from "../../contracts/marketPlace/index";
function DistributeLiquidity() {
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState(all_orders);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [marketModalData, setMarketModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const socket = useContext(SocketContext);
  useEffect(() => {
    if (socket) {
      socket.on("LiquidityDistributed", (res) => {
        console.log(res, "event received of distributed liquidity market==>");
        // if (marketModalData?.marketId) {
          setLoader(false);
        fetchMarketList();
        setMarketModalData(null);
        if (res?.success) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
        // }
        // alert("event received");
      });
    }
  }, [socket]);


  // useSelector((state) => console.log(state, "state==>"));
  const dispatch = useDispatch();

  useEffect(() => {
    setPagination(calculateRange(marketList, 5));
    // setMarketList(sliceData(marketList, page, 5));
    fetchMarketList();
  }, []);

  const fetchMarketList = async () => {
    const data = {
      url: "market/fetch_liquidity_markets",
      payload: {},
    };
    await getAxios(data).then((response) => {
      if (response?.data?.error) {
        setMarketList([]);
      } else {
        setMarketList(response?.data?.data);
      }
    });
  };


  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = marketList.filter(
        (item) =>
          item.first_name.toLowerCase().includes(search.toLowerCase()) ||
          item.last_name.toLowerCase().includes(search.toLowerCase()) ||
          item.product.toLowerCase().includes(search.toLowerCase())
      );
      // setMarketList(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    // setMarketList(sliceData(marketList, new_page, 5));
  };

  const handleDistributeLiquidity = async (marketAddress, marketId) => {
    setLoader(true);
    await distributeLiquidity(marketAddress, (txHash) => {
      addNewTransaction({
        data: { marketAddress },
        txHash,
        id: marketId,
        status: "pending",
      });
    });
  };

  const addNewTransaction = (data) => {
    dispatch(addTransaction(data));
  };


  return (
    <>
       <Loader
        loading={loader}
        loadingText="Distributing liquidity please wait"
      /> 
      <div className="dashboard-content">
        <DashboardHeader />
    
        <div className="dashboard-content-container">
          <div className="dashboard-content-header">
            <h2>Liquidity</h2>
            <div className="dashboard-content-search">
              <input
                type="text"
                value={search}
                placeholder="Search.."
                className="dashboard-content-input"
                onChange={(e) => __handleSearch(e)}
              />
            </div>
          </div>

          <table>
            <thead>
              <th>ID</th>
              <th>TYPE NAME</th>
              <th>CATEGORY</th>
              <th>QUESTION</th>
              <th>ACTIVE</th>
              <th>ARCHIVED</th>
              <th>IMAGE</th>
              <th>CLOSED</th>
              <th>ACTIONS</th>
            </thead>

            {marketList.length !== 0 ? (
              <tbody>
                {marketList.map((market, index) => (
                  <tr key={index}>
                    <td>
                      <span>{index + 1}</span>
                    </td>
                    <td>
                      <span>{market.typeName}</span>
                    </td>
                    <td>
                      <span>{market.category}</span>
                    </td>
                    <td>
                      <span>{market.question}</span>
                    </td>
                    <td>
                      <span>
                        {market.active ? (
                          <img
                            src={DoneIcon}
                            alt="paid-icon"
                            className="dashboard-content-icon"
                          />
                        ) : (
                          <img
                            src={CancelIcon}
                            alt="canceled-icon"
                            className="dashboard-content-icon"
                          />
                        )}
                      </span>
                    </td>
                    <td>
                      <span>
                        {market.archived ? (
                          <img
                            src={DoneIcon}
                            alt="paid-icon"
                            className="dashboard-content-icon"
                          />
                        ) : (
                          <img
                            src={CancelIcon}
                            alt="canceled-icon"
                            className="dashboard-content-icon"
                          />
                        )}
                      </span>
                    </td>
                    <td>
                      <div>
                        <img
                          src={market.image}
                          className="dashboard-content-avatar"
                          alt="Market Picture"
                        />
                      </div>
                    </td>
                    <td>
                      <span>
                        {market.closed ? (
                          <img
                            src={DoneIcon}
                            alt="paid-icon"
                            className="dashboard-content-icon"
                          />
                        ) : (
                          <img
                            src={CancelIcon}
                            alt="canceled-icon"
                            className="dashboard-content-icon"
                          />
                        )}
                      </span>
                    </td>
                    <td>
                      <Box className="actions">
                        <Button
                          className="delete-btn"
                          data-id={market?._id}
                          size="small"
                          onClick={() => {
                            handleDistributeLiquidity(
                              market.factoryContractAddress,
                              market?._id
                            );
                            // handleResolveMarket(1, market.factoryContractAddress)
                          }}
                        >
                          Distribute
                        </Button>
                      </Box>
                      {/* <button>Edit</button>
                    <button className="delete-btn">Delete</button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
          </table>

          {marketList.length !== 0 ? (
            <div className="dashboard-content-footer">
              {pagination.map((item, index) => (
                <span
                  key={index}
                  className={item === page ? "active-pagination" : "pagination"}
                  onClick={() => __handleChangePage(item)}
                >
                  {item}
                </span>
              ))}
            </div>
          ) : (
            <div className="dashboard-content-footer">
              <span className="empty-table">No data</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DistributeLiquidity;
