import { getContractMethods, getWeb3 } from "../web3";
import contractInfo from "../contractInfo";
import { getMetamaskAddress } from "../web3";
const createMarket = async (
  eventDescription = "description",
  endTime = 1725021257000,
  handleCreateNewMarket
) => {
  try {
    const web3 = await getWeb3();
    const methods = await getContractMethods();
    // const gasPrice = await web3.eth.getGasPrice();
    const address = await getMetamaskAddress();
    console.log(address, "address=>");
    const createMarketTx = await methods
      .createMarket(eventDescription, endTime)
      .send({
        from: address,
        // gasPrice: web3.utils.toHex(gasPrice),
        // gasLimit: web3.utils.toHex(6000000),
      })
      .on("transactionHash", function (hash) {
        handleCreateNewMarket(hash,address);
        console.log(hash, "hash==>");
      });

    console.log(createMarketTx, "createMarketTx=>");
    return {
      success: true,
      factoryContractAddress:
        createMarketTx?.events?.MarketCreated?.returnValues?.marketAddress,
    };

    ///////second approach
    // const funcData = createMarketFunc.encodeABI();
    // const nonce = await web3.eth.getTransactionCount(
    //   "0x280329C07b530647A88Af6cb94E2587C439f8181"
    // );
    // const gasPrice = await web3.eth.getGasPrice();
    // console.log({ nonce, gasPrice, funcData }, "nonce=>");

    // const rawTransaction = {
    //   from: "0x280329C07b530647A88Af6cb94E2587C439f8181",
    //   //   nonce: web3.utils.toHex(nonce),
    //   gasPrice: web3.utils.toHex(gasPrice),
    //   gasLimit: web3.utils.toHex(6000000),
    //   to: contractInfo.createMarket.address,
    //   data: funcData,
    // };

    // const receipt = await web3.eth.sendTransaction(rawTransaction);
    // console.log(receipt, "receipt==>");
  } catch (err) {
    console.log(err, "error==>");
    return {
      success: false,
      err,
    };
    // throw new Error(err?.message)
  }
};
// createMarket();

export { createMarket };
