const SHOW_TRANSACTION_LOADER = 'SET_TRANSACTION_LOADER';
const ADD_TRANSACTION = 'ADD_TRANSACTION';
const HIDE_TRANSACTION_LOADER = 'HIDE_TRANSACTION_LOADER';
const SHOW_USER_LOGIN_LOADER = 'SHOW_USER_LOGIN_LOADER';
const SET_LOGIN_USER = 'SET_LOGIN_USER';
const HIDE_USER_LOGIN_LOADER = 'HIDE_USER_LOGIN_LOADER';
export {
  ADD_TRANSACTION,
  SHOW_TRANSACTION_LOADER,
  HIDE_TRANSACTION_LOADER,
  SHOW_USER_LOGIN_LOADER,
  SET_LOGIN_USER,
  HIDE_USER_LOGIN_LOADER,
};
