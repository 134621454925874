// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashbord-header-container {
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap:20px;
}
.dashbord-header-btn {
  padding: 0.8em;
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 8px;
  text-transform: uppercase;
  border: none;
  background-color: #d64f49;
  transition: all 0.1s ease-in-out;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  text-transform: capitalize;
}
.dashbord-header-btn:hover {
  /* color: #015c92; */
  background-color: #ac524e;
}
.dashbord-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashbord-header-right p {
  font-weight: 800;
  font-size: 14px;
  margin: 0 15px 5px 0;
}
.dashbord-header-icon {
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}
.dashbord-header-avatar {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardHeader/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,QAAQ;AACV;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,gCAAgC;EAChC,mEAAmE;EACnE,0BAA0B;AAC5B;AACA;EACE,oBAAoB;EACpB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".dashbord-header-container {\n  padding: 1em;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  gap:20px;\n}\n.dashbord-header-btn {\n  padding: 0.8em;\n  padding-left: 2em;\n  padding-right: 2em;\n  cursor: pointer;\n  color: #ffffff;\n  font-size: 0.9rem;\n  font-weight: 700;\n  border-radius: 8px;\n  text-transform: uppercase;\n  border: none;\n  background-color: #d64f49;\n  transition: all 0.1s ease-in-out;\n  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;\n  text-transform: capitalize;\n}\n.dashbord-header-btn:hover {\n  /* color: #015c92; */\n  background-color: #ac524e;\n}\n.dashbord-header-right {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.dashbord-header-right p {\n  font-weight: 800;\n  font-size: 14px;\n  margin: 0 15px 5px 0;\n}\n.dashbord-header-icon {\n  width: 20px;\n  margin-right: 10px;\n  cursor: pointer;\n}\n.dashbord-header-avatar {\n  width: 40px;\n  height: 40px;\n  cursor: pointer;\n  border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
