import DashboardIcon from "../assets/icons/dashboard.svg";
import ShippingIcon from "../assets/icons/shipping.svg";
import ProductIcon from "../assets/icons/product.svg";
import UserIcon from "../assets/icons/user.svg";
import Refunded from "../assets/icons/refunded.svg";
import CampaignIcon from "@mui/icons-material/Campaign";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
const sidebar_menu = [
  // {
  //   id: 1,
  //   icon: DashboardIcon,
  //   path: "/",
  //   title: "Dashboard",
  // },
  {
    id: 1,
    icon: TrendingUpIcon,
    path: "/market",
    title: "Market",
  },
  {
    id: 2,
    icon: CampaignIcon,
    path: "/announce-winner",
    title: "Annouce Winner",
  },
  {
    id: 3,
    icon: CurrencyExchangeIcon,
    path: "/withdraw-profit",
    title: "Withdraw Profit",
  },
  // {
  //   id: 5,
  //   icon: PermIdentityIcon,
  //   path: "/liquidity",
  //   title: "Distribute Liquidity",
  // },
  {
    id: 4,
    icon: PermIdentityIcon,
    path: "/profile",
    title: "My account",
  },
];

export default sidebar_menu;
