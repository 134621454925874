import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  TextField,
  Radio,
  RadioGroup,
  FilledInput,
  Alert,
  FormHelperText,
  TextareaAutosize,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createMarket } from '../../contracts/createMarket/index';
import {
  isMetaMaskInstalled,
  validateMetamask,
  redirectToMetaMaskExtension,
} from '../../contracts/web3';
import './styles.css';
import { postAxios } from '../../utils/api';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SocketContext from '../../context/socket';
import Loader from '../Common/Loader';
// export default function BasicDateTimePicker() {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={['DateTimePicker']}>
//         <DateTimePicker label="Basic date time picker" />
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }

const CreateModal = ({ open, handleClose, notification, fetchMarketList }) => {
  const theme = useTheme();
  const [formErrors, setFormErrors] = useState({});
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [typeName, setTypeName] = useState('');
  const [category, setCategory] = useState('');
  const [question, setQuestion] = useState('');
  // const [slug, setSlug] = useState('');
  const [active, setActive] = useState(Boolean);
  const [closed, setClosed] = useState(Boolean);
  const [archived, setArchived] = useState(Boolean);
  const [description, setDescription] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [image, setImage] = useState('');
  const [outcomes, setOutcomes] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const socket = useContext(SocketContext);
  const categories = [
    'Crypto',
    'Sports',
    'Music',
    'Politics',
    'Space',
    'Fair Wage'
  ];

  useEffect(() => {
    if (socket) {
      socket.on('marketCreated', (res) => {
        console.log('marketCreated event received===>');

        if (res?.success) {
          setLoading(false);
          setTypeName('');
          setCategory('');
          setQuestion('');
          setOutcomes([]);
          setEndDate('');
          // setSlug('');
          setDescription('');
          handleClose();
        }
        // notification(res.message);
        // console.log(res, "event received==>");
        // alert("event received");
      });
    }
  }, [socket]);

  const multiOptions = [
    { title: 'YES', value: 'yes' },
    { title: 'NO', value: 'no' },
  ];
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOutcomes(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const saveFile = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFile(file);
    setImage(base64);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const names = [
    { title: 'YES', value: 'yes' },
    { title: 'NO', value: 'no' },
  ];
  const MAX_QUESTION_LENGTH = 128;
  const submit = async (e) => {
    try {
      const errors = {};

      if (!typeName) {
        errors.typeName = 'Type Name is required';
      }
      if (!category) {
        errors.category = 'Category is required';
      }
      if (!question) {
        errors.question = 'Question is required';
      }
      // if (!slug) {
      //   errors.slug = 'Slug is required';
      // }
      if (!endDate) {
        errors.endDate = 'End Date is required';
      }
      if (!description) {
        errors.description = 'Description is required';
      }
      if (question.length > MAX_QUESTION_LENGTH) {
        errors.question = `Question cannot exceed ${MAX_QUESTION_LENGTH} characters`;
      }
      // Add similar validation for other fields

      // If there are errors, set them in the state
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      // console.log(isMetaMaskInstalled(),"ismetamask installedF")
      if (isMetaMaskInstalled()) {
        const address = await validateMetamask();
        if (address) {
          const { factoryContractAddress, err } = await createMarket(
            question,
            new Date(endDate).getTime() / 1000,
            handleCreateNewMarket
          );
          console.log(factoryContractAddress, err, 'factoryContractAddress=>');
          if (!factoryContractAddress) {
            setLoading(false);
            // alert('something went wrong')
             toast.error("Transaction failed something went wrong");
          }
          // handleCreateNewMarket(factoryContractAddress);
        }
        return;
      }

      alert('Please install metamask to continue');
      redirectToMetaMaskExtension();
    } catch (err) {
      console.log(err, 'err');
      // toast.error(err?.message || 'Something went wrong');
      setLoading(false);
    }
  };
  const handleCreateNewMarket = async (txHash, fromAddress) => {
    try {
      const data = {
        url: 'market/store_market',
        payload: {
          typeName: typeName,
          category: category,
          question: question,
          // slug: slug,
          active: active,
          closed: closed,
          archived: archived,
          description: description,
          outcomes: outcomes,
          image: image,
          // factoryContractAddress,
          endDate: endDate,
          status: 'pending',
          winningOutCome: null,
          txHash,
          fromAddress,
        },
      };
      await postAxios(data).then((response) => {
        // setLoading(false);
        if (response?.data?.error) {
        } else {
          // setTypeName("");
          // setCategory("");
          // setQuestion("");
          // setOutcomes([]);
          // setEndDate("");
          // setSlug("");
          // setDescription("");
          // notification(response?.data?.message);
          // handleClose();
        }
      });
    } catch (err) {}
  };

  const closeModalHandle = () => {
    setTypeName('');
    setCategory('');
    setQuestion('');
    setOutcomes([]);
    // setSlug('');
    setDescription('');
    handleClose();
  };
  const addPendingTransactionMarket = async (factoryContractAddress) => {
    try {
      console.log('calling api==>');
      const data = {
        url: 'admin/market/transaction',
        payload: {
          data: {
            typeName: typeName,
            category: category,
            question: question,
            // slug: slug,
            active: active,
            closed: closed,
            archived: archived,
            description: description,
            outcomes: outcomes,
          },
          txHash: factoryContractAddress,
          status: 'pending',

          // image: image,
          // factoryContractAddress,
        },
      };
      const res = await postAxios(data);
      console.log(res, 'res=>');
    } catch (err) {
      console.log(err, 'error while  market store api ');
    }
  };

  return (
    <React.Fragment>
      {/* <Loader loading={loading} loadingText="Creating Market Please Wait...!" /> */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        // onClose={closeModalHandle}
        className='CreateMarketDialog_Wrapper'
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            // Only allow the dialog to close when the reason is not 'backdropClick'
            // You can add additional conditions if needed.
            closeModalHandle();
          }
        }}
        disableBackdropClick
      >
        <DialogTitle>Create New market</DialogTitle>
        <DialogContent className='marketModal_wrapper'>
          <Box className='marketModal_content'>
            <Box className='Type_Name'>
              <InputLabel>Type Name</InputLabel>
              <input
                className='type_Input'
                id='typeName'
                value={typeName}
                onChange={(e) => setTypeName(e.target.value)}
                variant='outlined'
                placeholder='Enter Type Name'
                onBlur={() => {
                  if (formErrors.typeName) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      typeName: '', // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.typeName && (
                <p className='error' style={{ color: 'red' }}>
                  {formErrors.typeName}
                </p>
              )}
            </Box>
            <Box className='Type_Name'>
              <InputLabel>Category</InputLabel>
              <Select
                className='outcome_Select'
                name='category'
                id='category'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                placeholder='Enter category'
              >
                {categories.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className='Type_Name'>
              <InputLabel>End Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    className='date-picker MuiStack-root'
                    onChange={(value) => {
                      console.log(new Date(value).getTime(), 'date==>');
                      setEndDate(value);
                    }}
                    onBlur={() => {
                      if (formErrors.endDate) {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          endDate: '', // Clear the error message for 'typeName'
                        }));
                      }
                    }}
                    shouldDisableDate={(date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0); // Set the time to midnight for comparison
                      return date < today;
                    }}
                    shouldDisableTime={(time, date) => {
                      if (!date) {
                        // Handle the case where the date is null (initialization)
                        return false; // You can choose to enable all times when date is null
                      }

                      const currentTime = new Date();
                      const selectedTime = new Date(date);

                      // If the date is today, disable times that are in the past
                      if (
                        selectedTime.toDateString() ===
                        currentTime.toDateString()
                      ) {
                        return time < currentTime;
                      }

                      return false; // Enable all other times
                    }}
                    // label="End Time"
                  />
                  {formErrors.endDate && (
                    <p className='error' style={{ color: 'red' }}>
                      {formErrors.endDate}
                    </p>
                  )}
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box className='Type_Name'>
              <InputLabel>Question</InputLabel>
              <input
                className='type_Input'
                name='question'
                id='question'
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder='Enter question'
                onBlur={() => {
                  if (formErrors.question) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      question: '', // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.question && (
                <p className='error' style={{ color: 'red' }}>
                  {formErrors.question}
                </p>
              )}
            </Box>
            {/* <Box className='Type_Name'>
              <InputLabel>Market Unique Id</InputLabel>
              <input
                className='type_Input'
                name='slug'
                id='slug'
                value={slug}
                // onChange={(e) => setSlug(e.target.value)}
                placeholder='Enter Unique Id'
                // onBlur={() => {
                //   if (formErrors.slug) {
                //     setFormErrors((prevErrors) => ({
                //       ...prevErrors,
                //       slug: '', // Clear the error message for 'typeName'
                //     }));
                //   }
                // }}
                disabled
              />
              {formErrors.slug && (
                <p className='error' style={{ color: 'red' }}>
                  {formErrors.slug}
                </p>
              )}
            </Box> */}
            {/* <Box className='Type_Name'>
              <InputLabel>Active</InputLabel>
              <RadioGroup
                name='active'
                id='active'
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <FormControlLabel
                  value='true'
                  label='Yes'
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#d64f49',
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value='false'
                  label='No'
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#d64f49',
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </Box>
            <Box className='Type_Name'>
              <InputLabel>Closed</InputLabel>
              <RadioGroup
                name='closed'
                id='closed'
                value={closed}
                onChange={(e) => setClosed(e.target.value)}
              >
                <FormControlLabel
                  value={true}
                  label='Yes'
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#d64f49',
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value='false'
                  label='No'
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#d64f49',
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </Box>
            <Box className='Type_Name'>
              <InputLabel>Archived</InputLabel>
              <RadioGroup
                name='archived'
                id='closarchiveded'
                value={archived}
                onChange={(e) => setArchived(e.target.value)}
              >
                <FormControlLabel
                  value={true}
                  label='Yes'
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#d64f49',
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value='false'
                  label='No'
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#d64f49',
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </Box> */}
            <Box className='Type_Name'>
              <InputLabel>Outcomes</InputLabel>
              <Select
                className='outcome_Select'
                labelId='demo-multiple-name-label'
                multiple
                id='demo-multiple-name'
                value={outcomes}
                onChange={handleChange}
                input={<OutlinedInput label='Name' />}
                MenuProps={MenuProps}
              >
                {names.map((name, index) => (
                  <MenuItem
                    key={`${name.title}-${index}`}
                    value={name?.title}
                    style={getStyles(name, personName, theme)}
                  >
                    {name?.title}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className='Type_Name'>
              <InputLabel>Upload Image</InputLabel>
              <input
                type='file'
                name='image'
                id='image'
                accept='.jpg, .jpeg, .png, .webp, image/webp'
                onChange={saveFile}
              />
            </Box>
            <Box className='Type_Name'>
              <InputLabel>Description</InputLabel>
              <TextareaAutosize
                className='description'
                name='image'
                id='image'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                minRows={3}
                maxRows={3}
                onBlur={() => {
                  if (formErrors.description) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      description: '', // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.description && (
                <p className='error' style={{ color: 'red' }}>
                  {formErrors.description}
                </p>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className='closeModal_Btn' onClick={closeModalHandle}>
            Close
          </Button>
          <Button
            className='closeModal_Btn'
            disabled={loading}
            onClick={submit}
          >
            {loading ? 'loading' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default CreateModal;
