import axios from "axios";
import {SERVER_ADDRESS} from "./constants";
import { ENV } from "../config";
const getConfigs = () => {
    const token = localStorage.getItem("token")
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            
        }
    }
}

export const getAxios = async (data) => {
    return await axios.get(`${ENV.serverUrl}/${data.url}`,data.payload, getConfigs());
}

export const postAxios = async (data) => {
    return await axios.post(`${ENV.serverUrl}/${data.url}`,data.payload, getConfigs());
}
