import {
  SHOW_TRANSACTION_LOADER,
  HIDE_TRANSACTION_LOADER,
} from "../../utils/constants";
const initialState = { isTransactionPending: false };
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TRANSACTION_LOADER:
      return { ...state, isTransactionPending: true };
    case HIDE_TRANSACTION_LOADER:
      return { ...state, isTransactionPending: false };
    default:
      return state;
  }
};

export default transactionReducer;
