import React, { forwardRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Slide,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import "./ResolveModal.css";
import MarketImage from "../../assets/images/AvatarImage.svg";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ResolveModal = (props) => {
  const {
    openEmail,
    closeEmailModal,
    data: { question, image, factoryContractAddress, _id },
    handleResolveMarket,
    setIsResolveMarketLoading,
    isResolveMarketLoading,
  } = props;
  const [checked, setChecked] = useState(false);
  return (
    <>
      <Dialog
        className="email-modal"
        open={openEmail}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeEmailModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="emailModal_Content">
          <Box className="content_wrapper">
            <Box className="icon_Email">
              {/* <MailOutlineIcon /> */}
              <img src={image} alt="market_image" />
              <Typography variant="h3">{question}</Typography>
            </Box>
            <Box className="security_Code">
              <Typography variant="body2">
                Please select one of the options{" "}
                <span style={{ fontWeight: "700" }}>"Yes or No"</span>
              </Typography>
              <Box className="copy_Code">
                {/* <Button className="code">Yes</Button>
                <Button className="copy">No</Button> */}
                <FormControl className="formControl_Wrapper">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    className="radio_Wrapper"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      onClick={() => setChecked(1)}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      onClick={() => setChecked(0)}
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  className="code"
                  disabled={
                    checked || checked === 0
                      ? false
                      : true || isResolveMarketLoading
                  }
                  onClick={() =>
                    handleResolveMarket(checked, factoryContractAddress, _id)
                  }
                >
                  {isResolveMarketLoading ? "Resolving" : "Resolve"}
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResolveModal;
