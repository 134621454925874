import {
  SHOW_USER_LOGIN_LOADER,
  HIDE_USER_LOGIN_LOADER,
  SET_LOGIN_USER,
} from '../../utils/constants';
const initialState = { isLogin: false, userInfo: null };
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_USER_LOGIN_LOADER:
      return { ...state, isLogin: true };
    case SET_LOGIN_USER:
      return { ...state, userInfo: action.data };
    case HIDE_USER_LOGIN_LOADER:
      return { ...state, isLogin: false };
    default:
      return state;
  }
};

export default userReducer;
