// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .circle-progress{
        color: white;
    }
    .circle-progress-text{
        color: white;
        margin-top: 2rem;  
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Common/Loader/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB;QACI,YAAY;IAChB;IACA;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ","sourcesContent":[".loader-content{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    .circle-progress{\n        color: white;\n    }\n    .circle-progress-text{\n        color: white;\n        margin-top: 2rem;  \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
