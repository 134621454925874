import React, { useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import DashboardHeader from '../../components/DashboardHeader';
import ProfileImage from '../../assets/images/AvatarImage.svg';
import { useSelector, useDispatch } from 'react-redux';
import './index.css';
import { updateProfile } from '../../redux/actions/users';

const MAX_FILE_SIZE = 12 * 1024 * 1024; // 12MB
const MAX_USERNAME_LENGTH = 30;
const ProfilePage = () => {
  const userDetail = localStorage.getItem('userDetail');
  const userData = JSON.parse(userDetail);

  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState();
  const [image, setImage] = useState(userData?.picture);
  const [error, setError] = useState('');
  const [username, setUsername] = useState(userData?.fullName);
  const [userError, setUserError] = useState('');
  const [email, setEmail] = useState(userData?.email);
  const [emailError, setEmailError] = useState('');

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const base64 = await convertToBase64(fileUploaded);
    setFile(fileUploaded);
    setImage(base64);
  };
  // const validateFile = (file) => {
  //   if (file.size > MAX_FILE_SIZE) {
  //     setError('Image size should not exceed 12MB.');
  //     return false;
  //   }
  //   return true;
  // };

  const validateUsername = (username) => {
    if (!username) {
      setUserError('Username is required');
    }
    if (username.length > MAX_USERNAME_LENGTH) {
      setUserError('Username should be at most 30 characters.');
      return false;
    }
    return true;
  };

  const isEmailValid = (email) => {
    // Regular expression for a valid email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = () => {
    if (!isEmailValid(email)) {
      setEmailError('Invalid email format');
      return;
    }
    if (validateUsername(username)) {
      if (userData?._id) {
        dispatch(
          updateProfile(userData?._id, {
            fullName: username,
            picture: image,
            email: email,
          })
        );
        clearFields();
      }
    }
  };

  const clearFields = () => {
    setUsername('');
    setImage('');
    setEmail('');
  };

  return (
    <Box className='profile_Wrapper'>
      <DashboardHeader />
      <Typography>My Account</Typography>
      <Box className='profile_Image_Content'>
        <img src={image ? image : userData?.picture} alt='profile' />

        <Box className='name_Address'>
          <Typography>{userData?.fullName}</Typography>
          <Typography>
            {userData?.address?.slice(0, 5) +
              '...' +
              userData?.address?.slice(-4)}
          </Typography>
        </Box>
      </Box>
      <Box ml={'38px'} mt={'10px'}>
        <input
          type='file'
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          accept='.jpg, .jpeg, .png'
        />
        <Button onClick={handleClick}>Upload Photo</Button>
        {/* {error && (
          <div className='error-message' style={{ color: 'red' }}>
            {error}
          </div>
        )} */}
      </Box>

      <Box className='profile_Form'>
        <form>
          <Box>
            <input
              className='input-form-style'
              type='username'
              defaultValue={userData?.fullName}
              placeholder='User Name'
              onChange={(e) => {
                setUsername(e.target.value);
                validateUsername(e.target.value);
              }}
              onBlur={() => {
                if (username.trim() !== '') {
                  setUserError(''); // Clear the error message
                }
              }}
            />
            {userError && (
              <div className='error-message' style={{ color: 'red' }}>
                {userError}
              </div>
            )}
          </Box>
          <Box>
            <input
              className='input-form-style'
              type='email'
              defaultValue={email}
              placeholder='Your Email'
              onChange={(e) => {
                const newEmail = e.target.value;
                setEmail(newEmail);
                if (!isEmailValid(newEmail)) {
                  setEmailError('Invalid email format');
                } else {
                  setEmailError('');
                }
              }}
              onBlur={() => {
                if (!email) {
                  setEmailError('Email is required');
                }
              }}
            />
            {emailError && (
              <div className='error-message' style={{ color: 'red' }}>
                {emailError}
              </div>
            )}
          </Box>
          <Box>
            <input
              className='input-form-style'
              type='address'
              defaultValue={userData?.address}
              placeholder='Wallet Address'
            />
          </Box>
          <Box className='formBtns_Wrapper'>
            <Button onClick={handleSubmit}>Save</Button>
            <Button>Cancel</Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ProfilePage;
