import { getRequest, postRequest } from "../../utils/request";
import {
  SHOW_TRANSACTION_LOADER,
  HIDE_TRANSACTION_LOADER,
} from "../../utils/constants";
import { SERVER_ADDRESS } from "../../../utils/constants";
export const addTransaction = (payload) => (dispatch) => {
  dispatch({ type: SHOW_TRANSACTION_LOADER });
  let url = SERVER_ADDRESS + "/transaction";
  postRequest(url, payload)
    .then((res) => {
      console.log(res, "res===>");
    })
    .catch((err) => {
      console.log(err, "err=>");
      dispatch({ type: HIDE_TRANSACTION_LOADER });
    });
};
