
import SideBar from "../components/Sidebar";
import sidebar_menu from "../constants/sidebar-menu";
const Layout = (props) => {
  return (
    <>
      <div className="dashboard-container">
        <SideBar menu={sidebar_menu} />

        <div className="dashboard-body">{props.children}</div>
      </div>
    </>
  );
};

export default Layout;
