import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useForm, SubmitHandler } from 'react-hook-form'; 
import { TextField, Radio, RadioGroup, FilledInput,Alert,FormHelperText } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import "./styles.css";
import { postAxios } from "../../utils/api";
const UpdateModal = ({ open, handleClose, data, notification }) => {
  const theme = useTheme();
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [typeName, setTypeName] = useState("");
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [endDate, setEndDate] = useState("");
  const [slug, setSlug] = useState("");
  const [active, setActive] = useState(Boolean);
  const [closed, setClosed] = useState(Boolean);
  const [archived, setArchived] = useState(Boolean);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [outcomes, setOutcomes] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [file, setFile] = useState(Boolean);
  const [fileName, setFileName] = useState("");
  const [marketID ,setMarketID] = useState("");
  const categories = [
    "Politics",
    "Weather",
    "Sport",
    "Science",
    "Pop Culture",
    "Crypto",
    "Business",
    "AI",
  ];


  useEffect(() => {
    if (data) {
      setTypeName(data?.typeName);
      setCategory(data?.category);
      setQuestion(data?.question);
      setSlug(data?.slug);
      setActive(data?.active);
      setClosed(data?.closed);
      setImage(data?.image);
      setArchived(data?.archived);
      setDescription(data?.description);
      setMarketID(data?._id);
      setOutcomes(data?.outcomes);
      setEndDate(data?.endDate);
      setFile(false);
    }
  }, [data]);
  
  const multiOptions = [
    { title: "YES", value: "yes" },
    { title: "NO", value: "no" },
  ];
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOutcomes(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
      
    );
  };
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const saveFile = async(e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setImage(base64);
    setFile(true);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const names = [
    { title: "YES", value: "yes" },
    { title: "NO", value: "no" },
  ];
  const closeModalHandle = () => {
    setTypeName("");
    setCategory("");
    setQuestion("");
    setOutcomes([]);
    setSlug("");
    handleClose();
}
  const submit = async (e) => {
    
    if(typeName === ""){    
      toast.warn("Type Name Field Required");
      return false;
    }
    if(category === ""){    
      toast.warn("Category Field Required");
      return false;
    }
    if(question === ""){    
      toast.warn("Question Field Required");
      return false;
    }
  
    if(slug === ""){    
      toast.warn("Slug Field Required");
      return false;
    }
   
  
    if(outcomes.length === 0){    
      toast.warn("OutComes Field Required");
      return false;
    }
  
  
    if(image === ""){    
      toast.warn("Market Image Required");
      return false;
    }
  
    if(description === ""){    
      toast.warn("Description Field Required");
      return false;
    }
    if (!endDate) {
      toast.warn("EndDate Field Required");
      return false;
    }
  

  const data = {
    url: "market/update_market",
    payload:  {
      id:marketID,
      market : {
        typeName:typeName,
        category:category,
        question:question,
        slug:slug,
        active:active,
        closed:closed,
        archived:archived,
        description:description,
        outcomes:outcomes,
        image:image,
        file:file
      }
    },
  };
  await postAxios(data).then((response) => {
    if (response?.data?.error) {

    } else {
      setTypeName("");
      setCategory("");
      setQuestion("");
      setOutcomes([]);
      setSlug("");
      notification(response?.data?.message);
      handleClose();
    }
  });

  
}

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={closeModalHandle}
      >
        <DialogTitle>Update New market</DialogTitle>
        <DialogContent className="marketModal_wrapper">
          <Box className="marketModal_content">
            <Box className="Type_Name">
              <InputLabel>Type Name</InputLabel>
              <input
                className="type_Input"
                id="typeName"
                value={typeName}
                onChange={(e) => setTypeName(e.target.value)}
                variant="outlined"
                placeholder="Enter Type Name"
              />
            </Box>
            <Box className="Type_Name">
              <InputLabel>Category</InputLabel>
              <Select
                className="outcome_Select"
                name="category"
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                placeholder="Enter category"
              >
                {categories.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {/* <Box className="Type_Name">
              <InputLabel>End Date</InputLabel>
              <LocalizationProvider  dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                  name="endDate"
                  id="endDate"
                  className="date-picker"
                    onChange={(value) => {
                      console.log(new Date(value).getTime(), "date==>");
                      setEndDate(value);
                    }}
                    // label="End Time"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box> */}
            <Box className="Type_Name">
              <InputLabel>Question</InputLabel>
              <input
                className="type_Input"
                name="question"
                id="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Enter question"
              />
            </Box>
            <Box className="Type_Name">
              <InputLabel>Slug</InputLabel>
              <input
                className="type_Input"
                name="slug"
                id="slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                placeholder="Enter Slug"
              />
            </Box>
            <Box className="Type_Name">
              <InputLabel>Active</InputLabel>
              <RadioGroup
                name="active"
                id="active"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <FormControlLabel
                  value="true"
                  label="Yes"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#d64f49",
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value="false"
                  label="No"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#d64f49",
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </Box>
            <Box className="Type_Name">
              <InputLabel>Closed</InputLabel>
              <RadioGroup
                name="closed"
                id="closed"
                value={closed}
                onChange={(e) => setClosed(e.target.value)}
              >
                <FormControlLabel
                  value={true}
                  label="Yes"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#d64f49",
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value="false"
                  label="No"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#d64f49",
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </Box>
            <Box className="Type_Name">
              <InputLabel>Archived</InputLabel>
              <RadioGroup
                name="archived"
                id="closarchiveded"
                value={archived}
                onChange={(e) => setArchived(e.target.value)}
              >
                <FormControlLabel
                   value={true}
                  label="Yes"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#d64f49",
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value="false"
                  label="No"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#d64f49",
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </Box>
            <Box className="Type_Name">
              <InputLabel>Outcomes</InputLabel>
              <Select
                className="outcome_Select"
                labelId="demo-multiple-name-label"
                multiple
                id="demo-multiple-name"
                value={outcomes}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
            <MenuItem
              key={name}
              value={name?.title}
              style={getStyles(name, personName, theme)}
            >
              {name?.title}
            </MenuItem>
          ))}
                </Select>
                
            </Box>
            <Box className="Type_Name">
              <InputLabel>Upload Image</InputLabel>
              <input
                type="file"
                name="image"
                id="image"
                onChange={saveFile}
              />
            </Box>
            <Box className="Type_Name">
              <InputLabel>Description</InputLabel>
              <TextField
                className="description"
                name="image"
                id="image"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className="closeModal_Btn" onClick={closeModalHandle}>
            Close
          </Button>
          <Button className="closeModal_Btn" onClick={submit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default UpdateModal;
