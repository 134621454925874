import React, { useEffect, useState } from 'react';

import './styles.css';
import NotificationIcon from '../../assets/icons/notification.svg';
import { getBalance } from '../../contracts/token';

// import SettingsIcon from "../../assets/icons/settings.svg";

function DashboardHeader({ btnText, onClick }) {
  const userDetail = localStorage.getItem('userDetail');
  const userData = JSON.parse(userDetail);
  const [availableBalance, setAvailableBalance] = useState('');
  const [address, setAddress] = useState('');
  useEffect(() => {
    getBalance(setAvailableBalance,setAddress)
  },[])

  return (
    <div className='dashbord-header-container'>
      {btnText && (
        <button className='dashbord-header-btn' onClick={onClick}>
          {btnText}
        </button>
      )}

      <div className='dashbord-header-right'>
       <div>
       <p>Wallet Address: &nbsp; {address?.slice(0, 6) + "..." + address?.slice(-4)}</p>
        <p>Current Balance: &nbsp;{availableBalance ? parseFloat(availableBalance).toFixed(4) : 'Loading...'} USDC</p>
       </div>
        {/* <img
          src={NotificationIcon}
          alt='notification-icon'
          className='dashbord-header-icon'
        /> */}
        {/* <img 
                    src={SettingsIcon}
                    alt='settings-icon'
                    className='dashbord-header-icon' /> */}
        <img className='dashbord-header-avatar' src={userData?.picture} />
      </div>
    </div>
  );
}

export default DashboardHeader;
