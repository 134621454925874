import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Logo from "../../assets/images/login_mockUp.svg";
import LoginModal from "../Modal/LoginModal";

const index = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(43deg, #171717 30.76%, #DB645E 120%)",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {/* <Container> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <Box className="loginImage">
          <Typography>Welcome to Predicto</Typography>
          <img src={Logo} alt="predicto" />
        </Box>
        <Box>
          <LoginModal />
        </Box>
      </Box>
      {/* </Container> */}
    </Box>
  );
};

export default index;
