import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import "./index.css";
const Loader = ({loading,loadingText="loading"}) => {
  return (
    <Backdrop
      sx={{
        color: "#5ebb45",
        marginTop: -46,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={loading}
    >
      <div className="loader-content">
        <CircularProgress className="circle-progress" />
        <p className="mt-3 circle-progress">{loadingText}</p>
      </div>
    </Backdrop>
  );
};

export default Loader;
