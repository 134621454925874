import React, { useState, useEffect, useContext } from 'react';
import DashboardHeader from '../../components/DashboardHeader';

import all_orders from '../../constants/orders';
import { calculateRange, sliceData } from '../../utils/table-pagination';

import '../styles.css';
import DoneIcon from '../../assets/icons/done.svg';
import CancelIcon from '../../assets/icons/cancel.svg';
import RefundedIcon from '../../assets/icons/refunded.svg';
import UpdateModal from '../../components/Modal/UpdateModal';
import CreateModal from '../../components/Modal/CreateModal';
import { Box, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAxios, postAxios } from '../../utils/api';
import ResolveModal from '../../components/Modal/ResolveModal';
import { resolveMarket } from '../../contracts/marketPlace/index';
import { useSelector, useDispatch } from 'react-redux';
import { addTransaction } from '../../redux/actions/transaction';
import SocketContext from '../../context/socket';
import WithdrawProfitRow from '../../components/WithdrawProfit/WithdrawProfitRow';
function Orders() {
  const [search, setSearch] = useState('');
  const [orders, setOrders] = useState(all_orders);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [marketData, setMarketData] = useState();
  const [isOpenResolveModal, setIsOpenResolveModal] = useState(false);
  const [marketModalData, setMarketModalData] = useState(null);
  const [isResolveMarketLoading, setIsResolveMarketLoading] = useState(false);
  const socket = useContext(SocketContext);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [slicedData, setSlicedData] = useState([]);

  const sliceDataForCurrentPage = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    return marketList?.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const sliced = sliceDataForCurrentPage();
    setSlicedData(sliced);
  }, [currentPage, marketList, recordsPerPage]);

  useEffect(() => {
    setPagination(calculateRange(marketList, recordsPerPage));
    fetchMarketList();
  }, []);

  useEffect(() => {
    setPagination(calculateRange(marketList, recordsPerPage));
  }, [marketList]);

  const openEmailModal = () => {
    setIsOpenResolveModal(true);
  };

  const closeEmailModal = () => {
    setIsOpenResolveModal(false);
  };

  useSelector((state) => console.log(state, 'state==>'));
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEditMarketClickOpen = () => {
    setEditOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    fetchMarketList();
  };
  const handleEditMarketClose = () => {
    setEditOpen(false);
    fetchMarketList();
  };

  // useEffect(() => {
  //   setPagination(calculateRange(marketList, 5));
  //   // setMarketList(sliceData(marketList, page, 5));
  //   fetchMarketList();
  // }, []);

  const fetchMarketList = async () => {
    const data = {
      url: 'market/fetch_market_list?status=created',
      payload: {},
    };
    await getAxios(data).then((response) => {
      if (response?.data?.error) {
        setMarketList([]);
      } else {
        const sortedMarkets = response?.data?.data.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
        
          // Sorting in descending order
          return dateB - dateA;
        });
        const updatedMarket = sortedMarkets?.map((market) => {
    
          if(market?.bets?.length > 0){
          const totalBets =  market?.bets?.reduce(
              (accumulator, currentValue) => accumulator + currentValue.amount, 0
            );
            return {...market, totalAmountOfBets: totalBets}
            // console.log('updateMarket ====>', updateMarket)
          }
          return market
        })
        setMarketList(updatedMarket);
      }
    });
  };

  const marketDelete = async (e) => {
    const marketId = e.target.getAttribute('data-id');
    const data = {
      url: 'market/delete_market',
      payload: {
        id: marketId,
      },
    };
    await postAxios(data).then((response) => {
      if (response?.data?.error) {
      } else {
        fetchMarketList();
      }
    });
  };

  const editMarket = async (e) => {
    const marketId = e.target.getAttribute('data-id');
    const data = {
      url: 'market/fetch_market',
      payload: {
        id: marketId,
      },
    };
    await postAxios(data).then((response) => {
      if (response?.data?.error) {
      } else {
        setEditOpen(true);
        setMarketData(response?.data?.data);
      }
    });
  };
  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== '') {
      let search_results = slicedData?.filter(
        (item) =>
          item?.question?.toLowerCase().includes(search.toLowerCase()) ||
          item?.slug?.toLowerCase().includes(search.toLowerCase()) ||
          item?.category?.toLowerCase().includes(search.toLowerCase())
      );
      setSlicedData(search_results);
    } else {
      resetSlicedData();
    }
  };

  const resetSlicedData = () => {
    setSlicedData(sliceDataForCurrentPage());
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setCurrentPage(new_page);
  };

  // const handleResolveMarket = async (outComeIndex, marketAddress, marketId) => {
  //   setIsResolveMarketLoading(true);
  //   await resolveMarket(outComeIndex, marketAddress, (txHash) => {
  //     addNewTransaction({
  //       data: { marketAddress },
  //       txHash,
  //       id: marketId,
  //       status: "pending",
  //     });
  //   });
  // };
  // const addNewTransaction = (data) => {
  //   dispatch(addTransaction(data));
  // };

  return (
    <div className='dashboard-content'>
      {/* <Modal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <CreateModal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        notification={(message) => toast.info(message)}
      />
      <UpdateModal
        data={marketData}
        open={editOpen}
        handleClickOpen={handleEditMarketClickOpen}
        handleClose={handleEditMarketClose}
        notification={(message) => toast.info(message)}
      />
      <DashboardHeader btnText='Create New' onClick={handleClickOpen} />      

      <div className='dashboard-content-container'>
        <div className='dashboard-content-header'>
          <h2>Markets List</h2>
          <div className='dashboard-content-search'>
            <input
              type='text'
              value={search}
              placeholder='Search with category or question'
              className='dashboard-content-input'
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>ID</th>
            <th>TYPE NAME</th>
            <th>CATEGORY</th>
            <th>QUESTION</th>
            <th>PROFIT%</th>
            <th>MARKET TOTAL</th>
            <th>IMAGE</th>
            <th>OUTCOME</th>
            {/* <th>ACTIONS</th> */}
          </thead>

          {slicedData?.length !== 0 ? (
            <tbody>
              {slicedData?.map((market, index) => (
                 <WithdrawProfitRow
                 index={index}
                 market={market}
                 currentPage={currentPage}
                 recordsPerPage={recordsPerPage}
                 setMarketModalData={setMarketModalData}
                 setIsOpenResolveModal={setIsOpenResolveModal}
                //  marketProfit={}
                 // fetchMarketData={fetchMarketList}
               />
              ))}
            </tbody>
          ) : null}
        </table>

        {slicedData?.length !== 0 ? (
          <div className='dashboard-content-footer'>
            {pagination.map((item, index) => (
              <span
                key={index}
                className={item === page ? 'active-pagination' : 'pagination'}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className='dashboard-content-footer'>
            <span className='empty-table'>No data</span>
          </div>
        )}
      </div>
      {/* {marketModalData && (
        <ResolveModal
          openEmail={isOpenResolveModal}
          openEmailModal={openEmailModal}
          closeEmailModal={closeEmailModal}
          data={marketModalData}
          handleResolveMarket={handleResolveMarket}
          setIsResolveMarketLoading={setIsResolveMarketLoading}
          isResolveMarketLoading={isResolveMarketLoading}
        />
      )} */}
    </div>
  );
}

export default Orders;
