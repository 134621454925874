import { getRequest, postRequest } from '../../utils/request';
import {
  SHOW_USER_LOGIN_LOADER,
  HIDE_USER_LOGIN_LOADER,
  SET_LOGIN_USER,
} from '../../utils/constants';
// import { useNavigate } from 'react-router-dom';
import { SERVER_ADDRESS } from '../../../utils/constants';
import { toast } from 'react-toastify';

export const signInUser = (payload, address, navigate) => (dispatch) => {
  dispatch({ type: SHOW_USER_LOGIN_LOADER });
  let url = SERVER_ADDRESS + `/admin/login/${address}`;
  postRequest(url, payload)
    .then((res) => {
      dispatch({ type: SET_LOGIN_USER, data: res?.data?._doc });
      localStorage.setItem(
        'userDetail',
        JSON.stringify({
          ...res.data?._doc,
          userID: res.data._doc?._id,
        })
      );
      toast.success(res.message);
      navigate('/market');
    })
    .catch((err) => {
      console.log(err, 'err=>');
      dispatch({ type: HIDE_USER_LOGIN_LOADER });
    });
};

export const updateProfile = (id, payload) => (dispatch) => {
  let url = SERVER_ADDRESS + `/client/userInfo/editProfile/${id}`;
  dispatch({ type: SHOW_USER_LOGIN_LOADER });
  postRequest(url, payload)
    .then((res) => {
      dispatch({
        type: SET_LOGIN_USER,
        data: res.data,
      });
      localStorage.setItem(
        'userDetail',
        JSON.stringify({
          ...res.data,
          userID: res.data._id,
        })
      );
      toast.success('Profile has been updated successfully');
    })
    .catch((err) => {
      console.log(err, 'err while updating user profile=>');
      dispatch({ type: HIDE_USER_LOGIN_LOADER });
    });
};
