import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SideBarItem from './sidebar-item';

import './styles.css';
import logo from '../../assets/images/light-logo.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import { ToastContainer, toast } from 'react-toastify';
function SideBar({ menu }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = useState(1);

  useEffect(() => {
    menu.forEach((element) => {
      if (location.pathname === element.path) {
        setActive(element.id);
      }
    });
  }, [location.pathname]);

  const logout = () => {
    localStorage.removeItem('userDetail');
    toast.success('User logout successfully');
    navigate('/login');
  };

  const __navigate = (id) => {
    setActive(id);
  };

  return (
    <nav className='sidebar'>
      <div className='sidebar-container'>
        <div className='sidebar-logo-container'>
          <img src={logo} alt='logo' />
        </div>

        <div className='sidebar-container'>
          <div className='sidebar-items'>
            {menu.map((item, index) => (
              <div key={index} onClick={() => __navigate(item.id)}>
                <SideBarItem
                  active={item.id === active}
                  item={item}
                  identity={item.id}
                />
              </div>
            ))}
          </div>

          <div className='sidebar-footer' onClick={logout}>
            <span className='sidebar-item-label'>Logout</span>
            <LogoutIcon />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
